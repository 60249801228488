/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser } from "@remix-run/react";
import { startTransition } from "react";
import { hydrateRoot } from "react-dom/client";
import mixpanel from "mixpanel-browser";
import { mixpanelToken, sentryDsn } from "~/lib/constants";
import * as Sentry from "@sentry/capacitor";
import * as SentryRemix from "@sentry/remix";
import { AFInit, AppsFlyer } from "appsflyer-capacitor-plugin";
import { Capacitor } from "@capacitor/core";
import { AppTrackingTransparency } from "capacitor-plugin-app-tracking-transparency";

Sentry.init(
  {
    enabled: import.meta.env.PROD,
    dsn: sentryDsn,
    environment: import.meta.env.MODE,
    integrations: [],
  },
  SentryRemix.init,
);

mixpanel.init(mixpanelToken, {
  debug: import.meta.env.DEV,
  track_pageview: true,
  persistence: "localStorage",
  api_host: "https://api-eu.mixpanel.com",
  ignore_dnt: true,
});

const afConfig: AFInit = {
  appID: "6532605311",
  devKey: "s8js7TrYFSPCkbsB4ToFqY",
  waitForATTUserAuthorization: 10,
  isDebug: import.meta.env.MODE !== "production",
  manualStart: true, // Start SDK manually after user consent
};

if (Capacitor.isNativePlatform()) {
  AppsFlyer.initSDK(afConfig).then((res) => {
    console.log("AppsFlyer initialized", res);
  });
  if (Capacitor.getPlatform() === "ios") {
    AppTrackingTransparency.requestPermission().then((res) =>
      console.log("ATT status: " + res.status),
    );
  }
}

startTransition(() => {
  hydrateRoot(document, <RemixBrowser />);
});
